<template>
  <v-flex xs12>
    <v-container fluid class="pa-0">
      <v-row>
        <v-col>
          <v-row class="px-6">
            <!-- Titulo -->
            <v-toolbar-title>Notas</v-toolbar-title>
            <v-divider class="mx-12"></v-divider>
            <!-- Filtro -->
            <v-divider class="mx-6" inset vertical></v-divider>
            <v-icon medium @click="filtro = !filtro"> mdi-filter</v-icon>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-list three-line>
      <template v-for="notas in notas">
        <v-list-item  class="notas">
          <v-list-item-content>
            <v-col class="pa-0" cols="10">
              <v-list-item-title>{{empresa(notas.empresa)}} | {{contacto(notas.contacto)}}</v-list-item-title>
              <v-list-item-subtitle class="mt-2">Vendedor: {{vendedor(notas.vendedor)}}</v-list-item-subtitle>
            </v-col>
            <v-col class="pa-0" cols="1">
              <v-icon v-if="notas.visto!='si'" @click="editItem(notas)" small>mdi-check-all</v-icon>
              <v-icon v-if="notas.visto=='si'" small color="primary">mdi-check-all</v-icon>
            </v-col>
            <v-col class="pa-0" cols="1">
              <v-icon class="d-flex justify-end" v-if="currentUser.rol!='vendedor'" @click="deleteItem(notas.id)" small>mdi-delete</v-icon>
            </v-col>
            <v-list-item-subtitle class="pt-2" v-html="notas.nota"></v-list-item-subtitle>
            <v-list-item-subtitle class="text-lg-right" v-html="new Date(notas.created_at).toLocaleString()"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-flex>
</template>

<script>
export default { 
  props:{message:Number},  
    data: () => ({
      menu:false,
      menu1:false,
      filtro: false,
      fechadeFilterValue:null,
      fechaaFilterValue:null,
      contactoFilterValue: null,
      empresaFilterValue: null,
      vendedorFilterValue:null,
      newNote:{
        contacto: '',
        empresa: '',
        nota: '',
        vendedor: '',
        visto:'',
      }
    }),
    computed: { 
      /* Trae datos del prospecto de la base de datos */ 
      notas:{
        get(){
          this.perro = this.$store.state.nota.notas
          if(this.message!=null){
            this.perro = this.$store.state.nota.notas.filter(nota => nota.empresa == this.message)
          }
          if(this.fechadeFilterValue!=null){
            this.perro = this.perro.filter(nota => new Date(nota.created_at).toISOString().slice(0,10) >= this.fechadeFilterValue)
          }
          if(this.fechaaFilterValue!=null){
            this.perro = this.perro.filter(nota => new Date(nota.created_at).toISOString().slice(0,10) <= this.fechaaFilterValue)
          }
          if(this.empresaFilterValue!=null){
            this.perro = this.perro.filter(nota => nota.empresa == this.empresaFilterValue)
          }
          if(this.contactoFilterValue!=null){
            this.perro = this.perro.filter(nota => nota.contacto  == this.contactoFilterValue)
          }
          if(this.vendedorFilterValue!=null){
            this.perro = this.perro.filter(nota => nota.vendedor  == this.vendedorFilterValue)
          }
          return this.perro.sort((b,a) => (a.created_at > b.created_at) ? 1 : ((b.created_at > a.created_at) ? -1 : 0)); 
        }
      },
      empresaLists() {
        return this.$store.state.empresa.empresas;
      },
      currentUser(){
        return this.$store.state.currentUser.user;
      },
      userLists(){
        return this.$store.state.user.users;
      },
      contactoLists() {
          return this.$store.state.contacto.contactos;
      },
      particularLists(){
        return this.contactoLists.filter(contacto => contacto.empresa == null);
      },
      ventaLists() {
          return this.$store.state.venta.cotizacion;
      },
    },
    created(){
      this.$store.dispatch('contacto/getContactos')
      this.$store.dispatch('empresa/getEmpresas')
      this.$store.dispatch('nota/getNotas')
      this.$store.dispatch('user/getUsers')
      this.$store.dispatch('currentUser/getUser')
    },
    methods: { 
      vendedor(id) {
        return this.userLists.filter(user => user.id === id).map(user => user.name)[0];
      },
        empresa(id) {
            var perro = this.empresaLists.filter(empresa => empresa.id === id).map(empresa => empresa.nombre)[0];
            if(perro != undefined){
              return perro
            }else{
              return ''
            }
        },
        contacto(id) {
          var perro = this.contactoLists.filter(contacto => contacto.id === id).map(contacto => contacto.nombre)[0];
          if(perro != undefined){
            return perro
          }else{
            return ''
          }
        },
        deleteItem (item) {
          if (confirm('¿Seguro que deseas borrar esta nota?')) {
            axios.delete('/api/v1/nota/borrar/'+item)
            this.$store.dispatch('nota/getNotas')
          }
        },
        editItem(item){
          this.editedIndex = this.notas.indexOf(item)
          this.newNote = Object.assign({}, item)
          this.$store.dispatch('nota/getNotas')
          this.newNote.visto = 'si'
          axios.put('/api/v1/nota/actualizar',Object.assign(this.newNote)).then((response) => {
            Object.keys(this.newNote).forEach(key => {
              return (this.newNote[key] = "");
            });
            this.$store.dispatch('nota/getNotas')
          })
        }
    },  
}
</script>

<style>
  .notas{
    background-color:#ffedb3;
    margin: 2%!important;
    width: 45%!important;
    display: inline-block;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
</style>